import {Stack} from "@mui/material";
import {Button} from "@mui/material";
import {Link} from "@mui/material";
import {Box} from "@mui/material";
import {Typography, IconButton, Drawer} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import {Paper} from "@mui/material";
import MantisLogo from "./Assets/mantisWhiteLogo.png";
import BackgroundImage from "./Assets/backgroundImage.png";
import FrontImage from "./Assets/frontImage.png";
import GoogleStore from "./Assets/googleButton.png";
import AppleStore from "./Assets/appleButton.png";
import GlobalBankLogo from "./Assets/globalbankLogo.png";
import LightBoxLogo from "./Assets/lightboxLogo.png";
import BoltshiftLogo from "./Assets/boltshiftLogo.png";
import FeatherDevLogo from "./Assets/featherdevLogo.png";
import NietzscheLogo from "./Assets/nietzscheLogo.png";
import SpheruleLogo from "./Assets/spheruleLogo.png";
import CardsMantis from "./Assets/cardsMantis.png";
import AWSLogo from "./Assets/awsLogo.png";
import GoogleLogo from "./Assets/googleLogo2.png";
import IBMLogo from "./Assets/ibmLogo.png";
import MongoDBLogo from "./Assets/mongoDBLogo.png";
import OpenAILogo from "./Assets/openAILogo.png";
import ShareLogo from "./Assets/share.png";
import CentralizeIcon from "./Assets/centralize.png";
import ReportesIcon from "./Assets/reportes.png";
import ProcesosIcon from "./Assets/procesos.png";
import LetterIcon from "./Assets/letter.png";
import InvoiceIcon from "./Assets/invoice.png";
import MoneyFlowIcon from "./Assets/money_flow.png";
import SafetyIcon from "./Assets/safety.png";
import StatisticIcon from "./Assets/statistic.png";
import MantisComponentsImage from "./Assets/mantisComponents.png";
import VisaIcon from "./Assets/visa.png";
import VectorLogo from "./Assets/Vector.png";
import MoneyLogo from "./Assets/money_talk.png";
import MenuLogo from "./Assets/menu.png";
import LockLogo from "./Assets/lock.png";
import RocketLogo from "./Assets/rocket.png";
import GmailLogo from "./Assets/gmailLogo.png";
import OutlookLogo from "./Assets/outlookLogo.png";
import GroupMantis from "./Assets/mantisBenefits.png";
import Icon1 from "./Assets/Icon1.png";
import NewDashPicture from "./Assets/newDashPicture.png";
import Icon2 from "./Assets/Icon2.png";
import Icon3 from "./Assets/Icon3.png";
import Icon4 from "./Assets/Icon4.png";
import Icon5 from "./Assets/Icon5.png";
import Icon6 from "./Assets/Icon6.png";
import BackgroundImage2 from "./Assets/backgroundImage2.png";
import GoogleFooterLogo from "./Assets/googleLogo.png";
import FacebookFooterLogo from "./Assets/facebookLogo.png";
import TwitterFooterLogo from "./Assets/twitterLogo.png";
import {createTheme, ThemeProvider} from "@mui/material/styles";
import {useState, useEffect} from "react";
import GooglePlayButtonCarousel from "./Assets/googlePlayButtonCarousel.png";
import AppleStoreButtonCarousel from "./Assets/appleStoreButtonCarousel.png";
import {Card, CardContent, Rating} from "@mui/material";
import YellowCard from "./Assets/yellowHeart.png";
import {useTheme} from "@mui/material/styles";
import {useMediaQuery} from "@mui/material";
import {Row, Col} from "reactstrap";
import MantisMobile from "./Assets/mantisMobile.png";
import {FaYoutube, FaInstagram, FaLinkedin} from "react-icons/fa";
import {businessUrl} from "../utils/backURL";
import "./styles/volcanoSummitHomepage.scss";

const theme = createTheme({
  typography: {
    fontFamily: "Garet, sans-serif"
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 900,
      lg: 1200,
      xl: 1536
    }
  }
});

export default function VolcanoSummitHomepage() {
  const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);

  const newTheme = useTheme();
  const isSmallScreen = useMediaQuery(newTheme.breakpoints.down("xs"));

  const slides = [
    {
      image: "01",
      description: "Crea tu cuenta",
      descriptionImages: [GooglePlayButtonCarousel, AppleStoreButtonCarousel]
    },
    {
      image: "02",
      description:
        "Conecta tus fuentes de datos o sube tus documentos manualmente.",
      descriptionImages: [GmailLogo, OutlookLogo]
    },
    {
      image: "03",
      description:
        "Asigna roles y permisos para tus colaboradores y ¡listo!, ya puedes hacer consultas, validar, analizar.",
      descriptionImages: [GroupMantis]
    }
  ];

  const comments = [
    {
      rating: 5,
      comment:
        "“Conecté mi correo y ahora toda mi información está en un solo lugar automáticamente, además no he vuelto a pensar en pagos porque todo lo hace Mantis por mí”",
      name: "Felipe Charris"
    },
    {
      rating: 5,
      comment:
        "“Conecté mi correo y ahora toda mi información está en un solo lugar automáticamente, además no he vuelto a pensar en pagos porque todo lo hace Mantis por mí”",
      name: "Jane Doe"
    },
    {
      rating: 4,
      comment:
        "“Conecté mi correo y ahora toda mi información está en un solo lugar automáticamente, además no he vuelto a pensar en pagos porque todo lo hace Mantis por mí”",
      name: "Alex Smith"
    }
  ];

  const faqData = [
    {
      question: "¿Cómo funciona?",
      answer: "Descarga, conecta tu info y deja que Mantis organiza tu archivo."
    },
    {
      question: "¿Qué puedo pagar?",
      answer: "Más de 5,000 convenios y transferencias por PSE."
    },
    {
      question: "¿Qué puedo hacer?",
      answer:
        "Centralizar información, automatizar pagos y recibir ayuda de tu asistente."
    },
    {
      question: "¿Mi info está segura?",
      answer: "Totalmente encriptada, solo tú decides quién accede."
    },
    {
      question: "¿Cómo hacen los pagos?",
      answer:
        "Envia la plata a Mantis y él se encarga por PSE o débito automático."
    },
    {
      question: "¿Qué documentos puedo tener?",
      answer: "Contratos, certificados, recibos, todo en un solo lugar!"
    },
    {
      question: "¿Diferencia con mi banco?",
      answer: "Mantis es tu persona para organizar el dia a dia."
    }
  ];

  const [currentSlide, setCurrentSlide] = useState(0);

  const handleLineClick = (index) => {
    setCurrentSlide(index);
  };

  const [activeButton, setActiveButton] = useState(null);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentSlide((prevSlide) => (prevSlide + 1) % slides.length);
    }, 6000);

    return () => clearInterval(interval);
  }, []);

  const calculateImageWidth = (slideIndex, imageIndex) => {
    switch (slideIndex) {
      case 0:
        return "20vh";
      case 1:
        return "10vh";
      case 2:
        if (isMobile) {
          return "25vh";
        }
        return "80vh";
      default:
        return "20vh";
    }
  };

  const calculateImageWidthMobile = (slideIndex, imageIndex) => {
    switch (slideIndex) {
      case 0:
        return "10vh";
      case 1:
        return "5vh";
      case 2:
        return "25vh";
      default:
        return "20vh";
    }
  };

  const [currentComment, setCurrentComment] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentComment((prevComment) => (prevComment + 1) % comments.length);
    }, 3000);

    return () => clearInterval(interval);
  }, []);

  const [currentIndex, setCurrentIndex] = useState(0);

  const handleNext = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % comments.length);
  };

  const handlePrev = () => {
    setCurrentIndex(
      (prevIndex) => (prevIndex - 1 + comments.length) % comments.length
    );
  };

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % comments.length);
    }, 3000);

    return () => clearInterval(interval);
  }, []);

  const [drawerOpen, setDrawerOpen] = useState(false);

  const toggleDrawer = () => {
    setDrawerOpen(!drawerOpen);
  };

  return (
    <>
      <Stack
        direction={{xs: "column", sm: "row"}}
        justifyContent="space-evenly"
        alignItems="center"
        p={3}
        sx={{backgroundColor: "#000000"}}
      >
        <img src={MantisLogo} alt="Mantis Logo" style={{width: "25vh"}} />
        <IconButton
          sx={{display: {xs: "block", sm: "none"}, color: "white"}}
          onClick={toggleDrawer}
        >
          <MenuIcon />
        </IconButton>
        <Stack
          direction="row"
          spacing={3}
          sx={{display: {xs: "none", sm: "flex"}}}
        >
          <Link
            href="#whyMantis"
            underline="none"
            sx={{color: "#FFFFFF", "&:hover": {color: "#F0F56C"}}}
          >
            Por qué elegirnos
          </Link>
          <Link
            href="#howItWorks"
            underline="none"
            sx={{color: "#FFFFFF", "&:hover": {color: "#F0F56C"}}}
          >
            Cómo funciona
          </Link>
          <Link
            href="#prices"
            underline="none"
            sx={{color: "#FFFFFF", "&:hover": {color: "#F0F56C"}}}
          >
            Precios
          </Link>
          <Link
            href="#faq"
            underline="none"
            sx={{color: "#FFFFFF", "&:hover": {color: "#F0F56C"}}}
          >
            FAQ
          </Link>
          <Link
            href="/aboutUs"
            underline="none"
            sx={{color: "#FFFFFF", "&:hover": {color: "#F0F56C"}}}
          >
            Sobre Mantis
          </Link>
        </Stack>
        <Stack
          direction="row"
          spacing={1}
          sx={{display: {xs: "none", sm: "flex"}}}
        >
          {/* <Button
            variant="outlined"
            sx={{
              color: "white",
              textTransform: "capitalize",
              borderColor: "#F0F56C",
              "&:hover": {borderColor: "#F0F56C"}
            }}
            onClick={() => (window.location.href = businessUrl)}
          >
            Log in
          </Button> */}
          <Button
            variant="contained"
            style={{
              backgroundColor: "#F0F56C",
              textTransform: "capitalize",
              width: 157,
              color: "black",
              fontFamily: "DM Sans",
              fontWeight: 600,
              fontSize: "1rem",
              "&:hover": {backgroundColor: "#F0F56C"}
            }}
            onClick={() => (window.location.href = businessUrl)}
          >
            Ingresar
          </Button>
        </Stack>
      </Stack>

      <Drawer
        anchor="top"
        open={drawerOpen}
        onClose={toggleDrawer}
        sx={{".MuiDrawer-paper": {backgroundColor: "#000000"}}}
      >
        <Stack
          direction="column"
          spacing={2}
          sx={{padding: "20px", backgroundColor: "#000000", color: "white"}}
        >
          <Link
            href="#whyMantis"
            underline="none"
            sx={{color: "#FFFFFF", "&:hover": {color: "#F0F56C"}}}
          >
            Por qué elegirnos
          </Link>
          <Link
            href="#howItWorks"
            underline="none"
            sx={{color: "#FFFFFF", "&:hover": {color: "#F0F56C"}}}
          >
            Cómo funciona
          </Link>
          <Link
            href="#faq"
            underline="none"
            sx={{color: "#FFFFFF", "&:hover": {color: "#F0F56C"}}}
          >
            FAQs
          </Link>
          <Link
            href="/aboutUs"
            underline="none"
            sx={{color: "#FFFFFF", "&:hover": {color: "#F0F56C"}}}
          >
            Sobre Mantis
          </Link>
        </Stack>
        <Stack
          direction="column"
          spacing={2}
          sx={{padding: "20px", backgroundColor: "#000000", color: "white"}}
        >
          <Button
            variant="contained"
            sx={{
              backgroundColor: "#F0F56C",
              textTransform: "capitalize",
              color: "black",
              "&:hover": {backgroundColor: "#F0F56C"}
            }}
          >
            <a
              style={{textDecoration: "none", color: "black"}}
              target="_blank"
              href="https://forms.gle/eVQAeyYeFUkjoquf7"
            >
              Solicitar una demostración
            </a>
          </Button>
        </Stack>
      </Drawer>

      <Box
        sx={{
          backgroundImage: `url(${BackgroundImage})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
          height: {xs: "800px", sm: "600px"},
          width: "100%",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center"
          //padding: {xs: "0 7.5%", sm: "0 10%"}
        }}
      >
        <Row>
          <Col
            xs={12}
            style={{
              width: isMobile ? "50%" : "55%",
              margin: "auto"
            }}
          >
            <h2 className="title">
              Automatiza procesos manuales 20X más rápido y reduce costos en más
              de un 60%
            </h2>
          </Col>
          <Col
            xs={12}
            style={{
              width: isMobile ? "55%" : "60%",
              margin: "auto"
            }}
          >
            <h3 className="subtitle">
              Deja que Mantis se encargue de tareas manuales relacionadas con
              información en PDFs, imágenes, exceles, correos y más.
            </h3>
          </Col>
          <Col
            xs={12}
            style={{
              width: "60%",
              margin: "auto",
              marginBottom: isMobile ? 15 : 80
            }}
          >
            <a
              style={{
                color: "white",
                textAlign: "center",
                fontFamily: "DM Sans",
                fontSize: "1.5rem",
                fontWeight: 400,
                marginTop: 20
              }}
              href="https://www.youtube.com/watch?v=az15JR_EzRg"
              target="_blank"
            >
              Ver video
            </a>
          </Col>
          <Col
            xs={6}
            md={12}
            style={{
              margin: "auto",
              marginTop: 20
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "center",
                marginBottom: 20
              }}
            >
              <div
                style={{
                  width: 262,
                  paddingLeft: 10,
                  paddingRight: 10,
                  height: 57,
                  borderRadius: "3.75px",
                  backgroundColor: "#F0F56C",
                  color: "#1D293F",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  fontWeight: 600,
                  fontSize: "1.2rem",
                  fontFamily: "DM Sans",
                  marginLeft: 12,
                  cursor: "pointer",
                  lineHeight: "1.5rem"
                }}
                onClick={() =>
                  window.open("https://forms.gle/eVQAeyYeFUkjoquf7", "_blank")
                }
              >
                Déjanos tu correo
              </div>
            </div>
          </Col>
        </Row>
      </Box>
      <div
        style={{
          width: "100%",
          backgroundColor: "#FDFFD2",
          paddingTop: 40,
          paddingBottom: 40
        }}
      >
        <div
          style={{
            width: "80%",
            justifyContent: "center",
            margin: "auto"
          }}
        >
          <div
            style={{
              textAlign: "left",
              marginBottom: 5,
              fontFamily: "DM Sans",
              fontSize: "1.1rem",
              fontWeight: 700
            }}
          >
            Trabajando con
          </div>
          <div
            style={{
              width: "100%",
              borderBottom: "1px solid #000000",
              marginBottom: 20
            }}
          ></div>
        </div>
        <Row>
          <Col xs={0} md={1}></Col>
          <Col xs={6} md={2}>
            <img
              src={AWSLogo}
              alt=""
              style={{
                height: "100px",
                width: "80px",
                objectFit: "contain"
              }}
            />
          </Col>
          <Col xs={6} md={2}>
            <img
              src={GoogleLogo}
              alt=""
              style={{
                height: "100px",
                width: "100px",
                objectFit: "contain"
              }}
            />
          </Col>
          <Col xs={6} md={2}>
            <img
              src={IBMLogo}
              alt=""
              style={{
                height: "100px",
                width: "100px",
                objectFit: "contain"
              }}
            />
          </Col>
          <Col xs={6} md={2}>
            <img
              src={MongoDBLogo}
              alt=""
              style={{
                height: "100px",
                width: "100px",
                objectFit: "contain"
              }}
            />
          </Col>
          <Col xs={12} md={2}>
            <img
              src={OpenAILogo}
              alt=""
              style={{
                height: "100px",
                width: "100px",
                objectFit: "contain"
              }}
            />
          </Col>
          <Col xs={0} md={1}></Col>
        </Row>
      </div>

      <div
        style={{
          width: "100%",
          backgroundColor: "#000000",
          paddingBottom: 40
        }}
      >
        <div
          style={{
            width: "90%",
            margin: "auto"
          }}
        >
          <h2
            style={{
              color: "#FFFFFF",
              textAlign: "center",
              padding: "50px 0",
              fontFamily: "DM Sans, sans-serif",
              fontWeight: "bold",
              fontSize: "2rem"
            }}
          >
            Una nueva forma de manejar la información de tu empresa con
            inteligencia artificial
          </h2>
          <Row>
            <Col xs={12} md={4}>
              <img src={CentralizeIcon} alt="" style={{width: 180}} />
              <h4
                style={{
                  color: "#FFFFFF",
                  textAlign: "center",
                  fontFamily: "DM Sans, sans-serif",
                  fontSize: "1.5rem"
                }}
              >
                Centraliza toda tu información y busca lo que necesites en
                segundos con lenguaje natural
              </h4>
            </Col>
            <Col xs={12} md={4}>
              <img src={ReportesIcon} alt="" style={{width: 180}} />
              <h4
                style={{
                  color: "#FFFFFF",
                  textAlign: "center",
                  fontFamily: "DM Sans, sans-serif",
                  fontSize: "1.5rem"
                }}
              >
                Reportes que antes se demoraban días ahora los tienes en tiempo
                real dando acceso a Mantis a los documentos
              </h4>
            </Col>
            <Col xs={12} md={4}>
              <img src={ProcesosIcon} alt="" style={{width: 180}} />
              <h4
                style={{
                  color: "#FFFFFF",
                  textAlign: "center",
                  fontFamily: "DM Sans, sans-serif",
                  fontSize: "1.5rem"
                }}
              >
                Esos procesos que son repetitivos y manuales de buscar datos,
                transcribirlos y hacer cálculos los puedes hacer con Mantis
              </h4>
            </Col>
          </Row>
          <h2
            style={{
              color: "#FFFFFF",
              textAlign: "center",
              padding: "50px 0",
              fontFamily: "DM Sans, sans-serif",
              fontWeight: "bold",
              fontSize: "2rem",
              marginTop: 50
            }}
          >
            Puedes hacer automatizaciones en varios procesos, mira algunos casos
            de uso
          </h2>
          <Row>
            <Col
              xs={12}
              md={4}
              style={{
                marginBottom: 50
              }}
            >
              <div
                style={{
                  height: 80,
                  width: 80,
                  borderRadius: "50%",
                  backgroundColor: "rgba(135, 135, 133, 0.25)",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  margin: "auto"
                }}
              >
                <img src={LetterIcon} alt="" style={{width: "50%"}} />
              </div>
              <h3
                style={{
                  color: "#FFFFFF",
                  textAlign: "center",
                  fontFamily: "DM Sans, sans-serif",
                  fontSize: "1.4rem",
                  marginTop: 20,
                  marginBottom: 20,
                  fontWeight: "bold"
                }}
              >
                Automatización de PQRs
              </h3>
              <h4
                style={{
                  color: "#FFFFFF",
                  textAlign: "center",
                  fontFamily: "DM Sans, sans-serif",
                  fontSize: "1.35rem"
                }}
              >
                Desde el buzón de PQRs puedes entrenar un flujo para
                automáticamente clasificar PQRs, definir tiempos, alertas y
                ejecutar respuestas
              </h4>
            </Col>
            <Col
              xs={12}
              md={4}
              style={{
                marginBottom: 50
              }}
            >
              <div
                style={{
                  height: 80,
                  width: 80,
                  borderRadius: "50%",
                  backgroundColor: "rgba(135, 135, 133, 0.25)",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  margin: "auto"
                }}
              >
                <img src={InvoiceIcon} alt="" style={{width: "50%"}} />
              </div>
              <h3
                style={{
                  color: "#FFFFFF",
                  textAlign: "center",
                  fontFamily: "DM Sans, sans-serif",
                  fontSize: "1.4rem",
                  marginTop: 20,
                  marginBottom: 20,
                  fontWeight: "bold"
                }}
              >
                Automatización de proveedores
              </h3>
              <h4
                style={{
                  color: "#FFFFFF",
                  textAlign: "center",
                  fontFamily: "DM Sans, sans-serif",
                  fontSize: "1.35rem"
                }}
              >
                Administra la relación de tus proveedores con todos los
                documentos que debes recibir, tenerlos al día y hacer validación
                de las facturas contra los contratos y facturas anteriores
              </h4>
            </Col>
            <Col
              xs={12}
              md={4}
              style={{
                marginBottom: 50
              }}
            >
              <div
                style={{
                  height: 80,
                  width: 80,
                  borderRadius: "50%",
                  backgroundColor: "rgba(135, 135, 133, 0.25)",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  margin: "auto"
                }}
              >
                <img src={MoneyFlowIcon} alt="" style={{width: "50%"}} />
              </div>
              <h3
                style={{
                  color: "#FFFFFF",
                  textAlign: "center",
                  fontFamily: "DM Sans, sans-serif",
                  fontSize: "1.4rem",
                  marginTop: 20,
                  marginBottom: 20,
                  fontWeight: "bold"
                }}
              >
                Procesos de cuadre y conciliación
              </h3>
              <h4
                style={{
                  color: "#FFFFFF",
                  textAlign: "center",
                  fontFamily: "DM Sans, sans-serif",
                  fontSize: "1.35rem"
                }}
              >
                Para procesos de cuadre y conciliación manuales donde se usan
                diferentes archivos como PDFs, exceles, imágenes y otras fuentes
              </h4>
            </Col>
            <Col
              xs={12}
              md={4}
              style={{
                marginBottom: 50
              }}
            >
              <div
                style={{
                  height: 80,
                  width: 80,
                  borderRadius: "50%",
                  backgroundColor: "rgba(135, 135, 133, 0.25)",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  margin: "auto"
                }}
              >
                <img src={SafetyIcon} alt="" style={{width: "50%"}} />
              </div>
              <h3
                style={{
                  color: "#FFFFFF",
                  textAlign: "center",
                  fontFamily: "DM Sans, sans-serif",
                  fontSize: "1.4rem",
                  marginTop: 20,
                  marginBottom: 20,
                  fontWeight: "bold"
                }}
              >
                Track de cumplimiento
              </h3>
              <h4
                style={{
                  color: "#FFFFFF",
                  textAlign: "center",
                  fontFamily: "DM Sans, sans-serif",
                  fontSize: "1.35rem"
                }}
              >
                Centraliza toda la información y flujos de cumplimiento para
                automatizar la búsqueda de información, creación de reportes y
                gestión de responsablildades
              </h4>
            </Col>
            <Col
              xs={12}
              md={4}
              style={{
                marginBottom: 50
              }}
            >
              <div
                style={{
                  height: 80,
                  width: 80,
                  borderRadius: "50%",
                  backgroundColor: "rgba(135, 135, 133, 0.25)",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  margin: "auto"
                }}
              >
                <img src={VisaIcon} alt="" style={{width: "50%"}} />
              </div>
              <h3
                style={{
                  color: "#FFFFFF",
                  textAlign: "center",
                  fontFamily: "DM Sans, sans-serif",
                  fontSize: "1.4rem",
                  marginTop: 20,
                  marginBottom: 20,
                  fontWeight: "bold"
                }}
              >
                Base de conocimiento
              </h3>
              <h4
                style={{
                  color: "#FFFFFF",
                  textAlign: "center",
                  fontFamily: "DM Sans, sans-serif",
                  fontSize: "1.35rem"
                }}
              >
                Para información en cientos de archivos y diferentes formatos,
                centralízala en un mismo lugar y consúltala en lenguaje natural
              </h4>
            </Col>
            <Col
              xs={12}
              md={4}
              style={{
                marginBottom: 50
              }}
            >
              <div
                style={{
                  height: 80,
                  width: 80,
                  borderRadius: "50%",
                  backgroundColor: "rgba(135, 135, 133, 0.25)",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  margin: "auto"
                }}
              >
                <img src={StatisticIcon} alt="" style={{width: "50%"}} />
              </div>
              <h3
                style={{
                  color: "#FFFFFF",
                  textAlign: "center",
                  fontFamily: "DM Sans, sans-serif",
                  fontSize: "1.4rem",
                  marginTop: 20,
                  marginBottom: 20,
                  fontWeight: "bold"
                }}
              >
                Reportes a clientes en tiempo real
              </h3>
              <h4
                style={{
                  color: "#FFFFFF",
                  textAlign: "center",
                  fontFamily: "DM Sans, sans-serif",
                  fontSize: "1.35rem"
                }}
              >
                Genera reportes en tiempo real a partir de información que está
                en múltiples archivos de distintos formatos y envíalos a tus
                clientes
              </h4>
            </Col>
          </Row>
          <h2
            style={{
              color: "#FFFFFF",
              textAlign: "center",
              padding: "50px 0",
              fontFamily: "DM Sans, sans-serif",
              fontWeight: "bold",
              fontSize: "2rem",
              marginTop: 40
            }}
          >
            Nos enfocamos en soluciones que puedas implementar sin escribir una
            sola línea de código y obtener:
          </h2>
          <img src={MantisComponentsImage} alt="" style={{width: "80%"}} />
          <Row>
            <Col xs={12} md={4}>
              <h4
                style={{
                  color: "#FFFFFF",
                  fontFamily: "DM Sans, sans-serif",
                  fontSize: "1.4rem",
                  marginTop: 20,
                  textAlign: "center"
                }}
              >
                • Procesos hasta 100X más rápidos
              </h4>
            </Col>
            <Col xs={12} md={4}>
              <h4
                style={{
                  color: "#FFFFFF",
                  fontFamily: "DM Sans, sans-serif",
                  fontSize: "1.4rem",
                  marginTop: 20,
                  textAlign: "center"
                }}
              >
                • Información en tiempo real
              </h4>
            </Col>
            <Col xs={12} md={4}>
              <h4
                style={{
                  color: "#FFFFFF",
                  fontFamily: "DM Sans, sans-serif",
                  fontSize: "1.4rem",
                  marginTop: 20,
                  textAlign: "center"
                }}
              >
                • Reducción de costos en hasta 60%
              </h4>
            </Col>
            <Col xs={12} md={6}>
              <h4
                style={{
                  color: "#FFFFFF",
                  fontFamily: "DM Sans, sans-serif",
                  fontSize: "1.4rem",
                  marginTop: 20,
                  textAlign: "center"
                }}
              >
                • Escalabilidad y y nuevas líneas de negocio
              </h4>
            </Col>
            <Col xs={12} md={6}>
              <h4
                style={{
                  color: "#FFFFFF",
                  fontFamily: "DM Sans, sans-serif",
                  fontSize: "1.4rem",
                  marginTop: 20,
                  textAlign: "center"
                }}
              >
                • Integración con tu información
              </h4>
            </Col>
          </Row>
        </div>
      </div>

      {isMobile ? (
        <Box
          sx={{
            backgroundImage: `url(${BackgroundImage2})`,
            backgroundSize: "cover",
            backgroundPosition: "center",
            backgroundRepeat: "no-repeat",
            height: "500px",
            width: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            padding: "10% 10%",
            backgroundColor: "black"
          }}
        >
          <Stack
            justifyContent="space-around"
            alignItems="center"
            padding={5}
            direction="column"
            spacing={2}
            sx={{
              maxWidth: "100%",
              height: "75%",
              color: "#FFFFFF",
              backgroundColor: "#F0F56C",
              borderRadius: "10px"
            }}
          >
            <Stack direction="column" maxWidth="75%">
              <ThemeProvider theme={theme}>
                <h3
                  style={{
                    color: "black",
                    textAlign: "left",
                    fontFamily: "DM Sans, sans-serif",
                    fontWeight: 700,
                    fontSize: "1.5rem"
                  }}
                >
                  Saquemos un espacio para que veas cómo Mantis puede ayudar a
                  tu compañía
                </h3>
              </ThemeProvider>
            </Stack>
            <Stack direction="column" spacing={2}>
              <a
                href="https://calendly.com/cristian-obac/30min"
                target="_blank"
                style={{
                  textDecoration: "none"
                }}
              >
                <div
                  style={{
                    alignItems: "center",
                    justifyContent: "center",
                    backgroundColor: "black",
                    padding: "10px",
                    borderRadius: "10px",
                    width: "150px",
                    height: "50px",
                    color: "black",
                    fontWeight: "bold",
                    cursor: "pointer",
                    display: "flex"
                  }}
                >
                  <h4
                    style={{
                      color: "white",
                      fontSize: "1.2rem",
                      textAlign: "center",
                      fontFamily: "DM Sans, sans-serif",
                      fontWeight: 600,
                      margin: "auto"
                    }}
                  >
                    Agendar
                  </h4>
                </div>
              </a>
            </Stack>
          </Stack>
        </Box>
      ) : (
        <Box
          sx={{
            backgroundImage: `url(${BackgroundImage2})`,
            backgroundSize: "cover",
            backgroundPosition: "center",
            backgroundRepeat: "no-repeat",
            height: "500px",
            width: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            padding: "0 10%",
            backgroundColor: "black"
          }}
        >
          <Stack
            justifyContent="space-around"
            alignItems="center"
            padding={3}
            direction="row"
            spacing={2}
            sx={{
              maxWidth: "85%",
              color: "#FFFFFF",
              backgroundColor: "#F0F56C",
              borderRadius: "10px"
            }}
          >
            <Stack direction="column" maxWidth="40%">
              <h3
                style={{
                  color: "black",
                  textAlign: "left",
                  fontFamily: "DM Sans, sans-serif",
                  fontWeight: 700,
                  fontSize: "1.5rem"
                }}
              >
                Saquemos un espacio para que veas cómo Mantis puede ayudar a tu
                compañía
              </h3>
            </Stack>
            <Stack direction="column" spacing={2}>
              <div
                style={{
                  alignItems: "center",
                  justifyContent: "center",
                  backgroundColor: "black",
                  borderRadius: "10px",
                  width: "150px",
                  height: "50px",
                  color: "black",
                  fontWeight: "bold",
                  cursor: "pointer",
                  display: "flex"
                }}
                onClick={() =>
                  window.open(
                    "https://calendly.com/cristian-obac/30min",
                    "_blank"
                  )
                }
              >
                <h4
                  style={{
                    color: "white",
                    fontSize: "1.2rem",
                    textAlign: "center",
                    fontFamily: "DM Sans, sans-serif",
                    fontWeight: 600,
                    margin: "auto"
                  }}
                >
                  Agendar
                </h4>
              </div>
            </Stack>
          </Stack>
        </Box>
      )}

      {isMobile ? (
        <Stack
          justifyContent="center"
          spacing={5}
          padding={3}
          direction="column"
          sx={{backgroundColor: "black", minWidth: "100%"}}
          alignItems="center"
        >
          <Row>
            <Col xs={12}>
              <img src={MantisLogo} style={{width: "25vh"}} />
            </Col>
          </Row>

          <Row>
            <Col xs={12}>
              <Typography
                variant="body2"
                sx={{color: "white", textAlign: "center"}}
              >
                Administra toda tu información estructurada y no estructurada
                desde un solo lugar sin tener un equipo dedicado
              </Typography>
            </Col>
          </Row>

          <Stack direction="column" spacing={3}>
            <Stack direction="column" spacing={1}>
              <Typography
                variant="body1"
                sx={{fontWeight: "bold", color: "white", textAlign: "center"}}
              >
                Mantis
              </Typography>
              <Typography
                variant="body2"
                sx={{color: "white", textAlign: "center"}}
                onClick={() => {
                  window.open("https://www.mantisapp.co/aboutUs", "_blank");
                }}
              >
                Sobre nosotros
              </Typography>

              <Typography
                variant="body2"
                sx={{color: "white", textAlign: "center"}}
                onClick={() => {
                  window.open(businessUrl, "_blank");
                }}
              >
                Plataforma
              </Typography>
            </Stack>

            <Stack direction="column" justifyContent="center" spacing={1}>
              <Typography
                variant="body1"
                sx={{fontWeight: "bold", color: "white", textAlign: "center"}}
              >
                Información
              </Typography>
              <Typography
                variant="body2"
                sx={{color: "white", textAlign: "center"}}
              >
                <a href="#faq" style={{textDecoration: "none", color: "white"}}>
                  Preguntas frecuentes
                </a>
              </Typography>
              <Typography
                variant="body2"
                sx={{color: "white", textAlign: "center"}}
                onClick={() => {
                  window.open(
                    "https://mantisapp.co/docs/dataTreatment",
                    "_blank"
                  );
                }}
              >
                Política de tratamiento de datos
              </Typography>
              <Typography
                variant="body2"
                sx={{color: "white", textAlign: "center"}}
                onClick={() => {
                  window.open(
                    "https://mantisapp.co/docs/terms&Conditions",
                    "_blank"
                  );
                }}
              >
                Términos y condiciones
              </Typography>
            </Stack>

            <Stack direction="column" justifyContent="center" spacing={1}>
              <Typography
                variant="body1"
                sx={{fontWeight: "bold", color: "white", textAlign: "center"}}
              >
                Contacto
              </Typography>
              <Typography
                variant="body2"
                sx={{color: "white", textAlign: "center"}}
              >
                Mantis APP S.A.S
              </Typography>
              <Typography
                variant="body2"
                sx={{color: "white", textAlign: "center"}}
              >
                Bogotá, Colombia
              </Typography>
              <Typography
                variant="body2"
                sx={{color: "white", textAlign: "center"}}
              >
                hola@mantisapp.co
              </Typography>
            </Stack>
          </Stack>
          <Row>
            <Col xs={12}>
              <Typography variant="body2" sx={{color: "white"}}>
                &copy; Mantis 2024, All Rights Reserved
              </Typography>
            </Col>
          </Row>
        </Stack>
      ) : (
        <Stack
          spacing={3}
          padding={3}
          direction="column"
          sx={{backgroundColor: "black"}}
          alignItems="center"
        >
          <Stack direction="row" justifyContent="space-between">
            <Stack direction="column" spacing={1} maxWidth="30%">
              <img src={MantisLogo} style={{width: "25vh"}} />
              <Typography
                variant="body2"
                sx={{color: "white", textAlign: "left", fontFamily: "DM Sans"}}
              >
                Automatiza tareas y procesa tu información en segundos.
              </Typography>
            </Stack>
            <Stack direction="column" spacing={1}>
              <Typography
                variant="body1"
                sx={{fontWeight: "bold", color: "white"}}
              >
                Mantis
              </Typography>
              <Typography
                variant="body2"
                sx={{color: "white"}}
                onClick={() => {
                  window.open("https://www.mantisapp.co/aboutUs", "_blank");
                }}
              >
                Sobre nosotros
              </Typography>

              <Typography
                variant="body2"
                sx={{color: "white"}}
                onClick={() => {
                  window.open(businessUrl, "_blank");
                }}
              >
                Plataforma
              </Typography>
            </Stack>
            <Stack direction="column" spacing={1}>
              <Typography
                variant="body1"
                sx={{fontWeight: "bold", color: "white"}}
              >
                Información
              </Typography>
              <Typography variant="body2" sx={{color: "white"}}>
                <a href="#faq" style={{textDecoration: "none", color: "white"}}>
                  Preguntas frecuentes
                </a>
              </Typography>
              <Typography
                variant="body2"
                sx={{color: "white"}}
                onClick={() => {
                  window.open(
                    "https://mantisapp.co/docs/dataTreatment",
                    "_blank"
                  );
                }}
              >
                Política de tratamiento de datos
              </Typography>
              <Typography
                variant="body2"
                sx={{color: "white"}}
                onClick={() => {
                  window.open(
                    "https://mantisapp.co/docs/terms&Conditions",
                    "_blank"
                  );
                }}
              >
                Términos y condiciones
              </Typography>
            </Stack>
            <Stack direction="column" spacing={1}>
              <Typography
                variant="body1"
                sx={{fontWeight: "bold", color: "white"}}
              >
                Contacto
              </Typography>
              <Typography variant="body2" sx={{color: "white"}}>
                Mantis APP S.A.S
              </Typography>
              <Typography variant="body2" sx={{color: "white"}}>
                Bogotá, Colombia
              </Typography>
              <Typography variant="body2" sx={{color: "white"}}>
                hola@mantisapp.co
              </Typography>
            </Stack>
          </Stack>
          <div
            style={{
              borderTop: "1px solid #FFF",
              width: "75%",
              height: "0",
              marginTop: "15px"
            }}
          />
          <Stack direction="row" spacing={65} justifyContent="space-between">
            <Typography variant="body2" sx={{color: "white"}}>
              &copy; Mantis 2024, All Rights Reserved
            </Typography>
          </Stack>
        </Stack>
      )}
    </>
  );
}
